<template>
    <div class="artworks">
        <Artwork
            :code="$route.params.code"
        ></Artwork>
    </div>
</template>
<script>
import Artwork from '@/components/Artwork.vue';

export default {
    name: 'ArtworkView',
    components: {
        Artwork
    }
};
</script>